<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Advanced node formatting</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('Cross click'); cache()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="w-100" *ngIf="edge" >
    <div class="form-group row px-3" (click)="selectedLabel='start'">
      <label class="w-25 col-form-label">Start Label</label>
      <div class="w-75 d-flex" *ngIf="edge.startLabel">
        <input #startLabelInput class="flex-grow-1 form-control form-text" type="text" [(ngModel)]="edge.startLabel.value">
        <button class="btn btn-outline-danger icon-50 ml-2"
                style="height: 38px; margin-top: 4px;"
                type="text"
                (click)="removeLabel('start');">
          <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        </button>
      </div>
      <button class="w-75 btn btn-primary" *ngIf="!edge.startLabel" (click)="edge.addStartLabel('')">
      Add start label
      </button>
    </div>

    <div class="form-group row px-3" (click)="selectedLabel='middle'">
      <label class="w-25 col-form-label">Middle Label</label>
      <div class="w-75 d-flex" *ngIf="edge.middleLabel">
        <input #middleLabelInput class="flex-grow-1 form-control form-text" type="text" [(ngModel)]="edge.middleLabel.value">
        <button class="btn btn-outline-danger icon-50 ml-2"
                style="height: 38px; margin-top: 4px;"
                type="text"
                (click)="removeLabel('middle');">
          <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        </button>
      </div>
      <button class="w-75 btn btn-primary" *ngIf="!edge.middleLabel" (click)="edge.addMiddleLabel('')">
        Add middle label
      </button>
    </div>

    <div class="form-group row px-3" (click)="selectedLabel='end'">
      <label class="w-25 col-form-label">End Label</label>
      <div class="w-75 d-flex" *ngIf="edge.endLabel">
        <input #endLabelInput class="flex-grow-1 form-control form-text" type="text" [(ngModel)]="edge.endLabel.value">
        <button class="btn btn-outline-danger icon-50 ml-2"
                style="height: 38px; margin-top: 4px;"
                type="text"
                (click)="removeLabel('end');">
          <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        </button>
      </div>
      <button class="w-75 btn btn-primary" *ngIf="!edge.endLabel" (click)="edge.addEndLabel('')">
        Add end label
      </button>
    </div>

    <hr>

    <div class="w-100 d-flex flex-row m-2">
      <div class="flex-grow-0 mr-2" style="line-height: 40px">Select Label: </div>
      <div class="btn-group btn-group-toggle flex-grow-1" style="height: 40px;" ngbRadioGroup name="radioBasic" [(ngModel)]="selectedLabel">
        <label ngbButtonLabel class="btn-outline-secondary flex-grow-1">
          <input ngbButton type="radio" value="start"> Start
        </label>
        <label ngbButtonLabel class="btn-outline-secondary flex-grow-1">
          <input ngbButton type="radio" value="middle"> Middle
        </label>
        <label ngbButtonLabel class="btn-outline-secondary flex-grow-1">
          <input ngbButton type="radio" value="end"> End
        </label>
      </div>
    </div>
    <app-special-character-selector (clickChar)="handleClickedChar($event)"></app-special-character-selector>

    <hr>

    <div class="form-group row px-3">
      <label class="w-25 col-form-label">Line Style</label>
      <div class="w-75">
        <select class="custom-select" [(ngModel)]="edge!.lineStyle">
          <option [ngValue]="0">Filled</option>
          <option [ngValue]="1">Dotted</option>
          <option [ngValue]="2">Dashed</option>
        </select>
      </div>
    </div>

    <div class="form-group row px-3">
      <label class="w-25 col-form-label">Start Style</label>
      <div class="w-75">
        <select class="custom-select" [(ngModel)]="edge!.startStyle">
          <option [ngValue]="0">None</option>
          <option [ngValue]="1">Small Filled Arrow</option>
          <option [ngValue]="2">Filled Diamond</option>
          <option [ngValue]="3">Unfilled Diamond</option>
          <option [ngValue]="4">Big Unfilled Arrow</option>
        </select>
      </div>
    </div>

    <div class="form-group row px-3">
      <label class="w-25 col-form-label">End Style</label>
      <div class="w-75">
        <select class="custom-select" [(ngModel)]="edge!.endStyle">
          <option [ngValue]="0">None</option>
          <option [ngValue]="1">Small Filled Arrow</option>
          <option [ngValue]="2">Filled Diamond</option>
          <option [ngValue]="3">Unfilled Diamond</option>
          <option [ngValue]="4">Big Unfilled Arrow</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div (click)="modal.close('Ok click')">
    <button type="button" class="btn btn-danger" (click)="delete()">
      Delete <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    </button>
  </div>
  <button type="button" ngbAutofocus class="btn btn-success" (click)="modal.close('Ok click'); cache()">
    Save <fa-icon [icon]="['fas', 'save']"></fa-icon>
  </button>
</div>

