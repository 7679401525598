<svg:pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
  <path d="M 10 0 L 0 0 0 10" fill="none" stroke="gray" stroke-width="0.5"/>
</svg:pattern>
<svg:pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
  <rect width="100" height="100" fill="url(#smallGrid)"/>
  <path d="M 100 0 L 0 0 0 100" fill="none" stroke="gray" stroke-width="1"/>
</svg:pattern>

<svg:rect *ngIf="active"
          [attr.x]="x"
          [attr.y]="y"
          [attr.width]="width"
          [attr.height]="height"
          fill="url(#grid)"
          (mousedown)="handleMouseDown($event)"/>
