<div class="form-check" >
  <input type="checkbox" class="form-check-input" (change)="activate()" [ngModel]="getSnapIsActive()">
  <label class="form-check-label" >Snappy</label>
</div>

<div class="form-check">
  <input type="checkbox" class="form-check-input" >
  <label class="form-check-label">Show Attachment Points</label>
</div>

<div class="form-check">
  <input type="checkbox" class="form-check-input" >
  <label class="form-check-label">Show Middle Points</label>
</div>

<div class="form-check">
  <input type="checkbox" class="form-check-input" >
  <label class="form-check-label">Grid</label>
</div>
