<svg:ellipse *ngFor="let point of node.getAllAttachmentPoints(); let i = index;"
  [attr.cx]="point.x"
  [attr.cy]="point.y"
  rx="10"
  ry="10"
  style="fill:orange;stroke:orange;stroke-width:2;fill-opacity:0.75;stroke-opacity:0.9"
  (mouseup)="handleMouseUp($event, i)"
  (mousedown)="handleMouseDown($event, i)"
  (click)="handleMouseDown($event, i)">
</svg:ellipse>
