<svg:g>
  <svg:ellipse [attr.cx]="node!.position.x + node!.width / 2"
               [attr.cy]="node!.position.y + node!.height / 2"
               [attr.rx]="node!.width / 2"
               [attr.ry]="node!.height / 2"
               [ngStyle]="node!.styleObject"
  >
  </svg:ellipse>
</svg:g>
<svg:line [attr.x1]="node!.position.x + node!.width / 2"
          [attr.y1]="node!.position.y + node!.height / 2"
          [attr.x2]="node!.position.x + 0.85 * node!.width"
          [attr.y2]="node!.position.y + node!.height / 2"
          stroke="black" stroke-width="3">
</svg:line>

<svg:line [attr.x1]="node!.position.x + node!.width / 2"
          [attr.x2]="node!.position.x + node!.width / 2"
          [attr.y1]="node!.position.y + node!.height / 2"
          [attr.y2]="node!.position.y"
          stroke="black" stroke-width="3">
</svg:line>
<!--<svg:g>-->
<!--  <svg:ellipse [attr.cx]="node!.position.x + node!.width / 2"-->
<!--               [attr.cy]="node!.position.y + node!.height / 2"-->
<!--               [attr.rx]="node.hasDoubleBorder ? node!.width / 2 - 6 : node!.width / 2"-->
<!--               [attr.ry]="node.hasDoubleBorder ? node!.height / 2 - 6 : node!.height / 2"-->
<!--               [ngStyle]="node!.styleObject"-->
<!--  >-->
<!--  </svg:ellipse>-->
<!--</svg:g>-->


<!--&lt;!&ndash; Double border if applicable &ndash;&gt;-->
<!--<svg:ellipse *ngIf="node?.hasDoubleBorder"-->
<!--             [attr.cx]="node!.position.x + node!.width / 2"-->
<!--             [attr.cy]="node!.position.y + node!.height / 2"-->
<!--             [attr.rx]="node!.width / 2"-->
<!--             [attr.ry]="node!.height / 2"-->
<!--             style="fill:none;stroke:black;stroke-width:2;fill-opacity:0;stroke-opacity:0.9"-->
<!--&gt;-->
<!--</svg:ellipse>-->

<!--<svg:g basic-node-text [node]="node"></svg:g>-->




