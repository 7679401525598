<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-2">
    <input class="form-control-file" type="file" (change)="onChange($event)">
  </div>

  <div class="col-md-2">
    <button class="btn btn-outline-success" (click)="onClick()">
      Upload
    </button>
  </div>
  <div class="col-md-4"></div>
</div>
