<svg:path [attr.d]="d"
          fill="#FFFF88"
          stroke="#c3c300"
          stroke-width="2"
>
</svg:path>

<svg:path [attr.d]="d2"
          fill="none"
          stroke="#c3c300"
          stroke-width="2"
>
</svg:path>


<svg:g basic-node-text [node]="node"></svg:g>
