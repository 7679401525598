<div>
  <app-nav-bar></app-nav-bar>

  <div style="overflow: hidden">
    <svg #svg
         [attr.viewBox]="zoomService.getViewBox()"
         (mousedown)="handleMouseDown($event)"
         (mouseup)="handleMouseUp($event)"
         (mousemove)="handleMouseMove($event)"
         id="diagram"
         style="overflow: hidden">
      <g white-background></g>
      <g grid-overlay></g>

      <defs arrow-marker></defs>

      <g *ngFor="let node of diagram.nodes" node-component [node]="node"/>

      <g *ngFor="let edge of diagram.edges; let i = index"
         edge-component [(edge)]="diagram.edges[i]" (edgeChange)="diagram.edges[i]=$event"></g>

      <g selected-node-highlight/>
      <g drag-select-preview/>
      <g drag-drop-preview></g>

      <g new-edge-preview></g>

      <svg:g *ngFor="let node of diagram.nodes">
        <svg:g *ngIf="edgeCreationIsActive" clickable-attachment-points [node]="node"/>
      </svg:g>
    </svg>
  </div>

  <creation-sidebar></creation-sidebar>
  <hint-overlay></hint-overlay>
</div>

<download-svg></download-svg>

<canvas id="canvas"></canvas>
