<svg:g>
  <svg:rect
            [attr.x]="node.position.x"
            [attr.y]="node.position.y"
            [attr.width]="node.width"
            [attr.height]="node.height"
            [ngStyle]="node.styleObject"
  >
  </svg:rect>


  <svg:text *ngFor="let line of textLines; let i = index;"
            [attr.x]="getTextLineX(i)"
            [attr.y]="node.position.y + i * 24 + 20"
            [attr.text-anchor]="getTextLineAnchor(  i)"
            style="font-size: 16px">
    {{line}}
  </svg:text>

  <svg:line [attr.x1]="node.position.x"
            [attr.x2]="node.position.x + node.width"
            [attr.y1]="node.position.y + (node.firstLine + 1) * 24"
            [attr.y2]="node.position.y + (node.firstLine + 1) * 24"
            style="stroke: black; stroke-width: 2px">
  </svg:line>
</svg:g>
