<svg:g>
  <svg:ellipse [attr.cx]="node!.position.x + node!.width / 2"
               [attr.cy]="node!.position.y + node!.height / 2"
               [attr.rx]="node.hasDoubleBorder ? node!.width / 2 - 6 : node!.width / 2"
               [attr.ry]="node.hasDoubleBorder ? node!.height / 2 - 6 : node!.height / 2"
               [ngStyle]="node!.styleObject"
  >
  </svg:ellipse>
</svg:g>


<!-- Double border if applicable -->
<svg:ellipse *ngIf="node?.hasDoubleBorder"
             [attr.cx]="node!.position.x + node!.width / 2"
             [attr.cy]="node!.position.y + node!.height / 2"
             [attr.rx]="node!.width / 2"
             [attr.ry]="node!.height / 2"
             style="fill:none;stroke:black;stroke-width:2;fill-opacity:0;stroke-opacity:0.9"
>
</svg:ellipse>

<svg:g basic-node-text [node]="node"></svg:g>

