<svg:text *ngIf="label"
          [attr.x]="label.position.x"
          [attr.y]="label.position.y"
          (mousedown)="handleMouseDown($event)"
          (dblclick)="handleDoubleClick($event)"
          [ngStyle]="{'fill': isSelected ? 'red' : 'black', 'cursor': isSelected ? 'move' : 'pointer'}"
          dominant-baseline="middle"
          text-anchor="middle">
      <svg:tspan *ngFor="let labelLine of textRows(); let i = index" [attr.x]="label.position.x" dy="1.2em">
        {{labelLine}}
      </svg:tspan>
</svg:text>
