<div class="modal-header text-center">
  <h4 class="modal-title" id="modal-title">Shape Set Selection</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="alert alert-info">Select which shape sets you want to use.</div>

  <div *ngFor="let courseKeyTuple of allKeyValues">
    <h4>{{courseKeyTuple[0]}}</h4>
    <div>
      <div *ngFor="let tuple of getCourseTuple(courseKeyTuple[0])" class="form-check">
        <input class="form-check-input"
               type="checkbox"
               [attr.id]="tuple[0]"
               [checked]="tuple[1].active"
               (change)="tuple[1].active = !tuple[1].active; update()">
        <label class="form-check-label" [attr.for]="tuple[0]">
          {{tuple[0]}}
        </label>
      </div>
    </div>
    <hr>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          ngbAutofocus
          class="btn btn-outline-secondary"
          (click)="modal.close('Ok click')">
    Close
  </button>
</div>
