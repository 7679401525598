<div class="modal-header text-center alert-danger">
  <h4 class="modal-title" id="modal-title">Error</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{errorMessage}}</p>
</div>
<div class="modal-footer">
  <button type="button"
          ngbAutofocus
          class="btn btn-outline-secondary"
          (click)="modal.close('Ok click')">
    Close
  </button>
</div>
