<div *ngIf="node">
  <div class="form-group row px-3">
    <label class="w-25 col-form-label">Width</label>
    <div class="w-75">
      <input class="w-100" type="number" [(ngModel)]="node.width">
    </div>
  </div>

  <div class="form-group row px-3">
    <label class="w-25 col-form-label">Height</label>
    <div class="w-75">
      <input class="w-100" type="number" [(ngModel)]="node.height">
    </div>
  </div>

  <div class="form-group row px-3">
    <label class="w-25 col-form-label" >Node Text<br><span class="text-secondary">('\n' = new line)</span></label>
    <div class="w-75">
      <textarea class="form-control" rows="5" class="w-100" [(ngModel)]="node.text"></textarea>
    </div>
  </div>

  <div class="form-group row px-3">
    <label class="w-25 col-form-label" >Double Border</label>
    <div class="w-75">
      <input type="checkbox" [(ngModel)]="node.hasDoubleBorder"></div>
  </div>


  <div  class="form-group row px-3" *ngFor="let key of node.styleKeys">
    <label class="w-25 col-form-label">{{key}}</label>
    <div class="w-75">
      <input class="w-100" type="text" [(ngModel)]="node.styleObject[key]">
    </div>
  </div>
</div>
