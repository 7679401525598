import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[arrow-marker]',
  templateUrl: './arrow-marker.component.html',
  styleUrls: ['./arrow-marker.component.scss']
})
export class ArrowMarkerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
