<svg:g node-render-dispatch
       [node]="node"
       (mousedown)="handleMouseDown($event)"
       (dblclick)="handleDoubleClick($event)"
       [ngStyle]="{'cursor': isSelected ? 'move' : 'pointer'}"/>

<!--Rectangle to make transparent nodes easily clickable.-->
<svg:rect *ngIf="node.transparent"
          [attr.x]="node.position.x"
          [attr.y]="node.position.y"
          [attr.width]="node.width"
          [attr.height]="node.height"
          (mousedown)="handleMouseDown($event)"
          (dblclick)="handleDoubleClick($event)"
          style="stroke: black; stroke-opacity: 0; stroke-width: 12; fill: none;"
          [ngStyle]="{'cursor': isSelected ? 'move' : 'pointer'}"/>

<svg:g *ngIf="startEndRepositioner.isActive()">
  <svg:ellipse *ngFor="let point of node.getAllAttachmentPoints(); let i = index;"
               [attr.cx]="point.x"
               [attr.cy]="point.y"
               rx="5"
               ry="5"
               style="fill:green;stroke:black;stroke-width:2;fill-opacity:1;stroke-opacity:0.9;z-index:100;">
  </svg:ellipse>
</svg:g>
