<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Save Diagram</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group d-flex">
    <label for="filenameInput">Name: </label>
    <input type="text" class="form-control flex-grow-1" id="filenameInput" [(ngModel)]="userService.openDiagramName">
  </div>
  <div class="d-flex justify-content-around">
    <button class="btn btn-outline-primary flex-grow-1 m-2"
            (click)="exportAsPNG()"
            ngbTooltip="Visual format which can easily be included in documents. Note you can not import a PNG in
this tool to edit it further."
            placement="bottom">
      Save as PNG
    </button>
    <button class="btn btn-outline-primary flex-grow-1 m-2"
            (click)="exportAsJSON()"
            ngbTooltip="In this format you can upload this file to UTML and continue where you left off at a later time."
            placement="bottom">
      Save as .UTML
    </button>
    <button *ngIf="this.userService.getAuthenticated()"
            class="btn btn-outline-primary flex-grow-1 m-2"
            (click)="saveToDBOrLaunchOverwriteModal()"
            ngbTooltip="You can later resume editing this diagram and you can also make it visible to others using a
unique URL."
            placement="bottom">
      Save to Database
    </button>
  </div>
</div>


<ng-template #overwriteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Overwrite Existing Diagram</h4>
  </div>
  <div class="modal-body">
    <div class="alert alert-warning">
      You already have a diagram with that name stored on the server. Would you like to overwrite it.
      The existing diagram will then be lost forever.
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close();">Cancel</button>
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(); saveToDB();">Overwrite</button>
  </div>
</ng-template>


