<svg:g *ngIf="node">
  <svg:ellipse *ngFor="let point of node.getAllResizePoints(); let i = index;"
    [attr.cx]="node.position.x + point.x"
    [attr.cy]="node.position.y + point.y"
    rx="5"
    ry="5"
    style="fill:red;stroke:black;stroke-width:2;fill-opacity:1;stroke-opacity:0.9"
    (mousedown)="handleClick($event, i);">
  </svg:ellipse>
</svg:g>


