<div id="creation-side-bar"
     (mouseup)="handleGenericMouseUp()">
  <ngb-accordion [closeOthers]="true">
    <ngb-panel *ngFor="let diagramGroup of groupKeys" title="{{diagramGroup}}">
      <ng-template ngbPanelContent>
        <ul class="list-group">
          <a
             class="list-group-item list-group-item-action"
             [ngClass]="{'selected': isSelected(diagramGroup, edgeKey) && edgeCreationIsActive}"
             *ngFor="let edgeKey of Object.keys(shapeSets[diagramGroup].edges)"
             (mousedown)="handleMouseDown(diagramGroup, edgeKey, 'edge')"
             (mouseup)="handleEdgeMouseUp(diagramGroup, edgeKey)"
             (dragstart)="false">
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1 w-100 text-center">{{edgeKey}}</h6>
            </div>
            <div class="w-100">
              <svg height="40" width="196">
                <svg:g edge-render-dispatch [edge]="shapeSets[diagramGroup].edges[edgeKey]"/>
                <svg:text *ngIf="shapeSets[diagramGroup].edges[edgeKey].middleLabel"
                          dominant-baseline="middle"
                          text-anchor="middle"
                          x="108"
                          y="33">
                  {{shapeSets[diagramGroup].edges[edgeKey].middleLabel!.value}}
                </svg:text>
              </svg>
            </div>
          </a>

          <a
             class="list-group-item list-group-item-action flex-column align-items-start"
             *ngFor="let nodeKey of Object.keys(shapeSets[diagramGroup].nodes)"
             (mousedown)="handleMouseDown(diagramGroup, nodeKey, 'node')"
             (dragstart)="false"
          >
            <div class="d-flex w-100 justify-content-between text-center">
              <h6 class="mb-1 w-100 text-center">{{nodeKey}}</h6>
            </div>
            <div class="w-100">
              <svg [attr.height]="shapeSets[diagramGroup].nodes[nodeKey].height + 2 * shapeSets[diagramGroup].nodes[nodeKey].position.y"
                   width="300">
                <g node-render-dispatch [node]="shapeSets[diagramGroup].nodes[nodeKey]"></g>
              </svg>
            </div>
          </a>
        </ul>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
