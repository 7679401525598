<svg:text
  font-family="sans-serif"
  [attr.font-size]="FONT_SIZE"
  class="movable">
  <svg:tspan
    *ngFor="let textLine of textLines; let i = index;"
    [attr.x]="centerX"
    [attr.y]="getY(i)"
    dominant-baseline="middle"
    text-anchor="middle">
    {{textLine}}
  </svg:tspan>
</svg:text>
