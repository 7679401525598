<svg:marker id="end-small-filled-arrow" viewbox="0 0 11 11" markerWidth="10" markerHeight="10" refX="9" refY="3" orient="auto" markerUnits="strokeWidth">
  <svg:path  d="M0,0 L0,6 L9,3 z" fill="#000" />
</svg:marker>

<svg:marker id="start-small-filled-arrow" viewbox="0 0 11 11" markerWidth="10" markerHeight="10" refX="9" refY="3" orient="auto-start-reverse" markerUnits="strokeWidth">
  <svg:path  d="M0,0 L0,6 L9,3 z" fill="#000" />
</svg:marker>

<svg:marker id="end-big-unfilled-arrow" viewbox="0 0 11 11" markerWidth="10" markerHeight="10" refX="9" refY="4" orient="auto" markerUnits="strokeWidth">
  <svg:path d="M0,0 L0,8 L9,4 z" fill="white" stroke="black" stroke-width="0.5px" />
</svg:marker>

<svg:marker id="start-big-unfilled-arrow" viewbox="0 0 11 11" markerWidth="10" markerHeight="10" refX="9" refY="4" orient="auto-start-reverse" markerUnits="strokeWidth">
  <svg:path d="M0,0 L0,8 L9,4 z" fill="white" stroke="black" stroke-width="0.5px" />
</svg:marker>
<!--Need start and end diamonds because the relative Positioning does not work.-->
<svg:marker id="start-filled-diamond" viewbox="0 0 11 11" markerWidth="10" markerHeight="10" refX="9" refY="3" orient="auto-start-reverse" markerUnits="strokeWidth">
  <svg:path d="M 0 3 L 4.5 0 L 9 3 L 4.5 6 z" fill="black" stroke="black" stroke-width="0.5px"/>
</svg:marker>

<svg:marker id="end-filled-diamond" viewbox="0 0 11 11" markerWidth="10" markerHeight="10" refX="9" refY="3" orient="auto" markerUnits="strokeWidth">
  <svg:path d="M 0 3 L 4.5 0 L 9 3 L 4.5 6 z" fill="black" stroke="black" stroke-width="0.5px"/>
</svg:marker>

<svg:marker id="start-unfilled-diamond" viewbox="0 0 11 11" markerUnits="strokeWidth" markerWidth="10" markerHeight="10" refX="9" refY="3" orient="auto-start-reverse" >
  <svg:path d="M 0 3 L 4.5 0 L 9 3 L 4.5 6 z" fill="white" stroke="black" stroke-width="0.5px"/>
</svg:marker>

<svg:marker id="end-unfilled-diamond" viewbox="0 0 11 11" markerUnits="strokeWidth" markerWidth="10" markerHeight="10" refX="9" refY="3" orient="auto" >
  <svg:path d="M 0 3 L 4.5 0 L 9 3 L 4.5 6 z" fill="white" stroke="black" stroke-width="0.5px"/>
</svg:marker>






