<svg:g edge-render-dispatch
       [edge]="edge!"
       [styleObject]="styleObject"/>

<!-- Invisible, selectable to make the mouse down trigger easily accessible -->
<svg:polyline *ngIf="edge && (isLine() || isFaultTree())"
              [attr.points]="edge.getPointString()"
              style="stroke:rgb(0, 0, 0); stroke-width:15; stroke-opacity: 0;  fill:none;"
              (mousedown)="handleMouseDown($event)"
              (dblclick)="handleDoubleClick($event)"
              [ngStyle]="{'cursor': cursor}"/>
<svg:path *ngIf="edge && isArc()"
          [attr.d]="edge.getPointString()"
          fill="none"
          style="stroke-width: 15; stroke: black; stroke-opacity: 0"
          (mousedown)="handleMouseDown($event)"
          (dblclick)="handleDoubleClick($event)"
          [ngStyle]="{'cursor': cursor}"/>

<svg:g *ngIf="isSelected && (isLine() || isFaultTree()) && !isFaultTree()" clickable-new-points [edge]="edge" pointer-events="visible"/>
<svg:g *ngIf="isSelected" clickable-start-end-points [edge]="edge"/>
<svg:g *ngIf="isSelected && !isFaultTree()" clickable-middle-points [edge]="edge"/>

<svg:g *ngIf="edge && edge?.startLabel"
       label-component
       [label]="edge.startLabel!">
</svg:g>

<svg:g *ngIf="edge && edge?.middleLabel"
       label-component
       [label]="edge.middleLabel!">
</svg:g>

<svg:g *ngIf="edge && edge?.endLabel"
       label-component
       [label]="edge.endLabel!">
</svg:g>
