<!--<div ngbDropdown class="d-inline-block">-->
<!--  <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Export</button>-->
<!--  <div *ngFor="let format of formats" ngbDropdownMenu aria-labelledby="dropdownBasic1">-->
<!--    <button ngbDropdownItem>{{ format }}</button>-->
<!--  </div>-->
<!--</div>-->
<!--<select  id="selectExport"[(ngModel)]="selectedFormat" (change)="export()" [title]=export>-->
<!--  <option *ngFor="let format of formats" [ngValue]="format">{{ format }}</option>-->
<!--</select>-->

<button class="btn btn-outline-success" (click)="export()">Export to PNG</button>
