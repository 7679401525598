<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Advanced node formatting</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('Cross click'); cache()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="node">
    <div class="form-group row px-3">
      <label class="w-25 col-form-label">Width</label>
      <div class="w-75">
        <input class="w-100 form-control" type="number" [(ngModel)]="node.width">
      </div>
    </div>
    <div class="form-group row px-3">
      <label class="w-25 col-form-label">Height</label>
      <div class="w-75">
        <input class="w-100 form-control" type="number" [(ngModel)]="node.height">
      </div>
    </div>
    <div class="form-group row px-3">
      <label class="w-25 col-form-label" >Text <br><span class="text-secondary"> (\n for a newline)</span></label>
      <div class="w-75">
        <textarea #nodeText class="form-control from-control-text w-70" rows="4" [(ngModel)]="node.text"></textarea>
      </div>
    </div>
    <div class="w-100">
      <app-special-character-selector (clickChar)="addChar($event)"></app-special-character-selector>
    <div class="form-group row px-3">
      <label class="w-25 col-form-label" >Double Border</label>
      <div class="w-75">
        <input class="form-control form-check" type="checkbox" [(ngModel)]="node.hasDoubleBorder"></div>
    </div>

    <div class="form-group row px-3" *ngIf="!node.transparent">
      <label class="w-25 col-form-label">Color</label>
      <div class="w-75">
        <input type="color" class="w-100" [(ngModel)]="node.styleObject['fill']">
      </div>
    </div>
    <div class="form-group row px-3">
      <label class="w-25 col-form-label">Opacity</label>
      <div class="w-75">
        <input type="range" class="w-100" min="0" max="1" step=0.05 [(ngModel)]="node.styleObject['fill-opacity']" >
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <div (click)="modal.close('Ok click')">
    <button type="button" class="btn btn-outline-danger" (click)="delete()">Delete</button>
  </div>
  <button type="button" ngbAutofocus class="btn btn-outline-primary" (click)="modal.close('Ok click'); cache();">Save</button>
</div>


