<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Open Diagram</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="flex-row d-flex">

    <div class="flex-grow-1 mr-2">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Upload File</a>
          <ng-template ngbNavContent>
              <div>
                <p>Upload a <code>.utml</code> file to resume the diagram creation process.</p>
                <input id="upload-button" class="form-control form-control-file" type="file" (change)="onChange($event)">
              </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>History</a>
          <ng-template ngbNavContent>
            <div class="alert alert-warning">
              Your history is an emergency backup, not as a primary means of storing your work!
              Please save your <code>.utml</code> if you want to store your work. Rely on this feature
              at your own risk.
            </div>
            <button class="btn btn-outline-danger w-100" (click)="openClearHistory()">Clear History</button>
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let pair of keyDiagramPairs; let i = index;" (click)="setLocalStorageDiagram(i)">
                <td>{{pair[0]}}</td>
                <td class="icon"><button class="btn btn-outline-danger text-center" style="width: 50px" (click)=removeLocalStorage(i)>
                  <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                </button></td>
              </tr>
              </tbody>
            </table>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>

    <div class="preview-container border-left" style="width:700px">
      <diagram-preview *ngIf="selectedDiagram" [height]="500" [width]="700" [diagram]="selectedDiagram!">

      </diagram-preview>
    </div>

  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Ok click')">Cancel</button>
  <div (click)="onClick()">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close('Ok click')">Open</button>
  </div>
</div>

<ng-template #clearHistoryModal let-modal>
  <div class="modal-header alert-danger">
    <h4 class="modal-title">Clear History</h4>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to clear your entire history? This action can not be undone.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()">Cancel</button>
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(); clearHistory();">Clear</button>
  </div>
</ng-template>

