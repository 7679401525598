<nav class="navbar navbar-expand   navbar-light bg-light pb-0 pt-0">
  <div class="collapse navbar-collapse" style="height: 50px;">
    <ul class="navbar-nav mr-auto pb-0 pt-0">
      <li class="nav-item">
        <a class="nav-link" (click)="openClearDiagramPopUp()" ngbTooltip="Clear Diagram" placement="bottom-left">
          <fa-icon [icon]="['fas', 'file']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="upload()" ngbTooltip="Open" placement="bottom">
          <fa-icon [icon]="['fas', 'folder-open']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="save()" ngbTooltip="Save" placement="bottom">
          <fa-icon [icon]="['fas', 'save']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="copy()" ngbTooltip="Copy (ctrl+c)" placement="bottom">
          <fa-icon [icon]="['fas', 'copy']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="paste()" ngbTooltip="Paste (ctrl+v)" placement="bottom">
          <fa-icon [icon]="['fas', 'paste']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"(click)="undo()" ngbTooltip="Undo (ctrl+z)" placement="bottom">
          <fa-icon [icon]="['fas', 'undo']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="redo()" ngbTooltip="Redo (ctrl+y)" placement="bottom">
          <fa-icon [icon]="['fas', 'redo']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  (click)="zoomOut()" ngbTooltip="Zoom Out" placement="bottom">
          <fa-icon [icon]="['fas', 'search-minus']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  (click)="resetZoom()" ngbTooltip="Reset Zoom" placement="bottom">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="zoomIn()" ngbTooltip="Zoom In" placement="bottom">
          <fa-icon [icon]="['fas', 'search-plus']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="delete()" ngbTooltip="Delete Selected" placement="bottom">
          <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  (click)="openShapeSetSelector()" ngbTooltip="Shape Set Selection" placement="bottom">
          <fa-icon [icon]="['fas', 'list']"></fa-icon>
        </a>
      </li>
      <!-- This is an experimental feature, currently removed from the app.-->
      <!--<li class="nav-item">-->
        <!--<a class="nav-link"  (click)="dfa()" ngbTooltip="DFA Export" placement="bottom">-->
          <!--<fa-icon [icon]="['fas', 'question']"></fa-icon>-->
        <!--</a>-->
      <!--</li>-->
    </ul>
  </div>

  <ul class="navbar-nav ml-auto pb-0 pt-0">
    <li class="nav-item">
      <a *ngIf="!userService.getAuthenticated()" class="nav-link" href="/login" ngbTooltip="Login" placement="bottom">
        <fa-icon [icon]="['fas', 'user']"></fa-icon>
      </a>
      <a *ngIf="userService.getAuthenticated()"
         (click)="openDiagramManagementModal()"
         class="nav-link"
         ngbTooltip="Manage Diagrams and Account"
         placement="bottom">
        {{userService.getUserEmail()}}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="help()" ngbTooltip="Help" placement="bottom">
        <fa-icon [icon]="['fas', 'question']"></fa-icon>
      </a>
    </li>
  </ul>
</nav>
