<div class="modal-header text-center">
  <h4 class="modal-title" id="modal-title">Diagram Management</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-row">
    <div class="flex-grow-1">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dbEntry of dbEntries; index as i"
            (click)="selectedIndex = i" class="clickable"
            [ngClass]="{selected: i === selectedIndex}">
          <td>{{dbEntry.title}}</td>
          <td class="icon">
            <button class="btn btn-outline-secondary text-center" style="width: 50px" (click)="triggerEditModal(i)"
                    ngbTooltip="Edit Title" placement="top">
              <fa-icon [icon]="['far', 'edit']"></fa-icon>
            </button>
          </td>
          <td class="icon">
            <button class="btn btn-outline-info text-center"
                    style="width: 50px"
                    (click)="toggleVisibility()"
                    ngbTooltip="Visibility" placement="top">
              <fa-icon *ngIf="dbEntry.visible" [icon]="['far', 'eye']"></fa-icon>
              <fa-icon *ngIf="!dbEntry.visible" [icon]="['far', 'eye-slash']"></fa-icon>
            </button>
          </td>
          <td class="icon">
            <button class="btn btn-outline-danger text-center" style="width: 50px" (click)="triggerDeleteModal()"
                    ngbTooltip="Delete" placement="top">
              <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    </div>

    <div class="border-left">
      <div *ngIf="selectedIndex !== -1 && dbEntries !== undefined && dbEntries[selectedIndex].visible" class="w-100 d-flex p-1 m-1">
        <div class="flex-grow-1">
          <input #urlElement class="form-control" type="text" [value]="url" readonly/>
        </div>
        <div class="flex-grow-0">
          <button class="btn btn-outline-primary ml-1" ngbTooltip="Copy to Clipboard" (click)="copyUrl()">
            <fa-icon [icon]="['fas', 'copy']"></fa-icon>
          </button>
        </div>
      </div>
      <hr>
      <diagram-preview *ngIf="selectedDiagram" [width]="500" [height]="500" [diagram]="selectedDiagram">

      </diagram-preview>
    </div>
  </div>


</div>
<div class="modal-footer">
  <a type="button" class="btn btn-outline-danger" href="/logout" (click)="modal.close();">Logout</a>
  <button type="button"
          ngbAutofocus
          class="btn btn-outline-primary"
          (click)="modal.close('Ok click'); setDiagram()">
    Import Diagram
  </button>
</div>

<ng-template #deleteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-danger" *ngIf="dbEntries">Delete {{dbEntries[selectedIndex].title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="dbEntries">Are you sure you want to delete {{dbEntries[selectedIndex].title}}?</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()">Cancel</button>
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(); delete()">Delete</button>
  </div>
</ng-template>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Title</h4>
  </div>
  <div class="modal-body">
    <input class="form-control" *ngIf="dbEntries" [(ngModel)]="editTitle" type="text">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="modal.close(); updateChanges();">Save</button>
  </div>
</ng-template>
